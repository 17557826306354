import request from '@/utils/request'

// 分页
export function fetchList(query) {
  return request({
    url: '/biz/app-version-info-assign/page',
    method: 'get',
    params: query
  })
}

// 删除
export function delObj(data) {
  return request({
    url: '/biz/app-version-info-assign/delete',
    method: 'post',
    data
  })
}
